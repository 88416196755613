.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.div-auth {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.btn-login{
  background: #282c34;
  color:aliceblue;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  margin-right: 10px
}

.btn-logout{
  background: red;
  color:aliceblue;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  margin-left: 10px
}


.div-status{
  margin: 50px 10px; 
  border: 1px solid blue;
  padding: 20px;
}

.btn-send{
  background: #282c34;
  color:aliceblue;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
